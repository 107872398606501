// import React, { useEffect, useState } from 'react';
// import './SettlementLetter.css';

// const SettlementLetter = ({
//   recipientName,
//   recipientAddress,
//   claimReferenceNumber
// }) => {
//   const [settlementData, setSettlementData] = useState(null);
//   const [discrepancyData, setDiscrepancyData] = useState([]);
//   const [claimedAmount, setClaimedAmount] = useState(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         // Fetch settlement data from the policy-status endpoint
//         const settlementResponse = await fetch('http://localhost:5000/policy-status');
//         if (!settlementResponse.ok) {
//           throw new Error(`HTTP error! status: ${settlementResponse.status}`);
//         }
        
//         const settlementData = await settlementResponse.json();
//         setSettlementData(settlementData);

//         // Prepare the discrepancy data
//         const discrepancyArray = [
//           {
//             name: "Sub Limit",
//             deductionAmount: settlementData.policy_wording_document.sub_limit,
//             reason: settlementData.policy_wording_document.information.sub_limit.reason
//           },
//           {
//             name: "Proportional Deduction",
//             deductionAmount: settlementData.policy_wording_document.proportional_deduction,
//             reason: settlementData.policy_wording_document.information.proportional_deduction.reason
//           },
//           {
//             name: "Total Deductible",
//             deductionAmount: settlementData.policy_wording_document.total_deductible,
//             reason: settlementData.policy_wording_document.information.total_deductible.reason
//           },
//           {
//             name: "Total Copayment",
//             deductionAmount: settlementData.policy_wording_document.total_copayment,
//             reason: settlementData.policy_wording_document.information.total_copayment.reason
//           },
//           ...settlementData.nonpayables.categories.items_non_payable_but_billed_in_the_bill.map((item) => ({
//             name: item.item_name,
//             deductionAmount: item.value,
//             reason: item.reason
//           })),
//           ...settlementData.tariff.necessary_items.map((item) => ({
//             name: item.item_name,
//             deductionAmount: item.billed_amount - item.price,
//             reason: item.justification
//           })),
//           ...settlementData.tariff.unnecessary_items.map((item) => ({
//             name: item.item_name,
//             deductionAmount: item.billed_amount,
//             reason: item.justification
//           }))
//         ];
//         setDiscrepancyData(discrepancyArray);

//         // Fetch claimed amount from claim-amounts endpoint
//         const claimAmountResponse = await fetch('http://localhost:5000/claim-amounts');
//         if (!claimAmountResponse.ok) {
//           throw new Error(`HTTP error! status: ${claimAmountResponse.status}`);
//         }
//         const claimAmountData = await claimAmountResponse.json();
//         setClaimedAmount(claimAmountData.initial_total_net_amount);  // Adjust based on the actual response structure

//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };

//     fetchData();
//   }, []);

//   if (!settlementData || claimedAmount === null) {
//     return <p>Loading...</p>;  // Render a loading state while data is being fetched
//   }

//   return (
//     <div className="settlement-container">
//       <header className="settlement-header">
//         <div className="header-content">
//           <img src="/logonew.svg" alt="Company Logo" className="company-logo" />
//           <div className="claim-info">
//             <p>Claim Reference Number: <strong>{claimReferenceNumber}</strong></p>
//             <p>(Please quote this reference number in all future correspondence)</p>
//           </div>
//         </div>
//       </header>

//       <main className="settlement-main">
//         <p>Date: {new Date().toLocaleDateString()}</p>
//         <p>To,<br />
//           {recipientName},<br />
//           {recipientAddress}
//         </p>

//         <p>Dear Partner,</p>

//         <p>
//           Claim bearing No {claimReferenceNumber} against the policy issued by our company has been settled for ₹{settlementData.ice_authorized_amount} against the amount claimed for ₹{claimedAmount}.
//           The details of the payment are as follows:
//         </p>

//         <section className="section">
//           <h3>Detailed Working Sheet for Deductions</h3>
//           <table className="settlement-table">
//             <thead>
//               <tr>
//                 <th>S.No</th>
//                 <th>Name of the Item</th>
//                 <th>Deduction Amount (INR)</th>
//                 <th>Reason</th>
//               </tr>
//             </thead>
//             <tbody>
//               {discrepancyData && discrepancyData.map((item, index) => (
//                 <tr key={index}>
//                   <td>{index + 1}</td>
//                   <td>{item.name}</td>
//                   <td>{item.deductionAmount}</td>
//                   <td>{item.reason}</td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </section>
//       </main>
//     </div>
//   );
// }

// export default SettlementLetter;




// import React, { useEffect, useState } from 'react';
// import './SettlementLetter.css';
// import jsPDF from 'jspdf';
// import 'jspdf-autotable';

// const SettlementLetter = ({
//   recipientName,
//   recipientAddress,
//   claimReferenceNumber
// }) => {
//   const [settlementData, setSettlementData] = useState(null);
//   const [discrepancyData, setDiscrepancyData] = useState([]);
//   const [claimedAmount, setClaimedAmount] = useState(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const settlementResponse = await fetch('http://localhost:5000/policy-status');
//         if (!settlementResponse.ok) {
//           throw new Error(`HTTP error! status: ${settlementResponse.status}`);
//         }
        
//         const settlementData = await settlementResponse.json();
//         setSettlementData(settlementData);

//         const discrepancyArray = [
//           {
//             name: "Sub Limit",
//             deductionAmount: settlementData.policy_wording_document.sub_limit,
//             reason: settlementData.policy_wording_document.information.sub_limit.reason
//           },
//           {
//             name: "Proportional Deduction",
//             deductionAmount: settlementData.policy_wording_document.proportional_deduction,
//             reason: settlementData.policy_wording_document.information.proportional_deduction.reason
//           },
//           {
//             name: "Total Deductible",
//             deductionAmount: settlementData.policy_wording_document.total_deductible,
//             reason: settlementData.policy_wording_document.information.total_deductible.reason
//           },
//           {
//             name: "Total Copayment",
//             deductionAmount: settlementData.policy_wording_document.total_copayment,
//             reason: settlementData.policy_wording_document.information.total_copayment.reason
//           },
//           ...settlementData.nonpayables.categories.items_non_payable_but_billed_in_the_bill.map((item) => ({
//             name: item.item_name,
//             deductionAmount: item.value,
//             reason: item.reason
//           })),
//           ...settlementData.tariff.necessary_items.map((item) => ({
//             name: item.item_name,
//             deductionAmount: item.billed_amount - item.price,
//             reason: item.justification
//           })),
//           ...settlementData.tariff.unnecessary_items.map((item) => ({
//             name: item.item_name,
//             deductionAmount: item.billed_amount,
//             reason: item.justification
//           }))
//         ];
//         setDiscrepancyData(discrepancyArray);

//         const claimAmountResponse = await fetch('http://localhost:5000/claim-amounts');
//         if (!claimAmountResponse.ok) {
//           throw new Error(`HTTP error! status: ${claimAmountResponse.status}`);
//         }
//         const claimAmountData = await claimAmountResponse.json();
//         setClaimedAmount(claimAmountData.initial_total_net_amount);

//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };

//     fetchData();
//   }, []);

//   // const downloadPDF = () => {
//   //   const doc = new jsPDF({
//   //     encoding: "UTF-8"
//   //   });

//   //   const marginLeft = 14;
//   //   const lineHeight = 10;

//   //   doc.setFontSize(12);
//   //   doc.setLineHeightFactor(1.5);

//   //   doc.text("Settlement Letter", marginLeft, 20);
//   //   doc.text(`Date: ${new Date().toLocaleDateString()}`, marginLeft, 30);
//   //   doc.text(`To: ${recipientName}`, marginLeft, 40);
//   //   doc.text(`${recipientAddress}`, marginLeft, 50);
//   //   doc.text(`Claim Reference Number: ${claimReferenceNumber}`, marginLeft, 60);

//   //   // Split the text into smaller chunks
//   //   const part1 = `Claim bearing No ${claimReferenceNumber} against the policy issued by our company has been settled for `;
//   //   const part2 = `₹${settlementData.ice_authorized_amount} against the amount claimed for ₹${claimedAmount}. `;
//   //   const part3 = "The details of the payment are as follows:";

//   //   doc.text(part1, marginLeft, 70);
//   //   doc.text(part2, marginLeft, 80);
//   //   doc.text(part3, marginLeft, 90);

//   //   doc.autoTable({
//   //     startY: 100,
//   //     head: [['S.No', 'Name of the Item', 'Deduction Amount (INR)', 'Reason']],
//   //     body: discrepancyData.map((item, index) => [
//   //       index + 1,
//   //       item.name,
//   //       item.deductionAmount,
//   //       item.reason,
//   //     ]),
//   //     styles: {
//   //       fontSize: 10,
//   //       cellPadding: 3,
//   //       overflow: 'linebreak',
//   //     },
//   //     columnStyles: {
//   //       0: { cellWidth: 20 },
//   //       1: { cellWidth: 40 },
//   //       2: { cellWidth: 30 },
//   //       3: { cellWidth: 80 },
//   //     },
//   //   });

//   //   doc.save('ice_settlement_letter.pdf');
//   // };

//   const downloadPDF = async () => {
//     try {
//       const response = await fetch('http://localhost:5000/save-settlement', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           recipientName,
//           recipientAddress,
//           claimReferenceNumber,
//           settlementData,
//           discrepancyData,
//           claimedAmount
//         }),
//       });
  
//       if (response.ok) {
//         const blob = await response.blob();
//         const url = window.URL.createObjectURL(blob);
//         const a = document.createElement('a');
//         a.href = url;
//         a.download = 'ice_settlement_letter.pdf';
//         document.body.appendChild(a);
//         a.click();
//         a.remove();
//         window.URL.revokeObjectURL(url);
//       } else {
//         console.error('Failed to download PDF');
//       }
//     } catch (error) {
//       console.error('Error generating PDF:', error);
//     }
//   };
  
  
//   if (!settlementData || claimedAmount === null) {
//     return <p>Loading...</p>;
//   }

//   return (
//     <div className="settlement-container">
//       <header className="settlement-header">
//         <div className="header-content">
//           <img src="/logonew.svg" alt="Company Logo" className="company-logo" />
//           <div className="claim-info">
//             <p>Claim Reference Number: <strong>{claimReferenceNumber}</strong></p>
//             <p>(Please quote this reference number in all future correspondence)</p>
//           </div>
//         </div>
//       </header>

//       <main className="settlement-main">
//         <p>Date: {new Date().toLocaleDateString()}</p>
//         <p>To,<br />
//           {recipientName},<br />
//           {recipientAddress}
//         </p>

//         <p>Dear Partner,</p>

//         <p>
//           {`Claim bearing No ${claimReferenceNumber} against the policy issued by our company has been settled for ₹ ${settlementData.ice_authorized_amount} against the amount claimed for ₹ ${claimedAmount}. `}
//           The details of the payment are as follows:
//         </p>

//         <section className="section">
//           <h3>Detailed Working Sheet for Deductions</h3>
//           <table className="settlement-table">
//             <thead>
//               <tr>
//                 <th>S.No</th>
//                 <th>Name of the Item</th>
//                 <th>Deduction Amount (INR)</th>
//                 <th>Reason</th>
//               </tr>
//             </thead>
//             <tbody>
//               {discrepancyData && discrepancyData.map((item, index) => (
//                 <tr key={index}>
//                   <td>{index + 1}</td>
//                   <td>{item.name}</td>
//                   <td>{item.deductionAmount}</td>
//                   <td>{item.reason}</td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </section>
      
//         <button onClick={downloadPDF}>Download PDF</button>
//       </main>
//     </div>
//   );
// }

// export default SettlementLetter;







// import React, { useEffect, useState } from 'react';
// import './SettlementLetter.css';
// import jsPDF from 'jspdf';
// import 'jspdf-autotable';

// const SettlementLetter = ({
//   recipientName,
//   recipientAddress,
//   claimReferenceNumber
// }) => {
//   const [settlementData, setSettlementData] = useState(null);
//   const [discrepancyData, setDiscrepancyData] = useState([]);
//   const [claimedAmount, setClaimedAmount] = useState(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const settlementResponse = await fetch('http://localhost:5000/policy-status');
//         if (!settlementResponse.ok) {
//           throw new Error(`HTTP error! status: ${settlementResponse.status}`);
//         }
        
//         const settlementData = await settlementResponse.json();
//         setSettlementData(settlementData);

//         const discrepancyArray = [
//           {
//             name: "Sub Limit",
//             deductionAmount: settlementData.policy_wording_document.sub_limit,
//             reason: settlementData.policy_wording_document.information.sub_limit.reason
//           },
//           {
//             name: "Proportional Deduction",
//             deductionAmount: settlementData.policy_wording_document.proportional_deduction,
//             reason: settlementData.policy_wording_document.information.proportional_deduction.reason
//           },
//           {
//             name: "Total Deductible",
//             deductionAmount: settlementData.policy_wording_document.total_deductible,
//             reason: settlementData.policy_wording_document.information.total_deductible.reason
//           },
//           {
//             name: "Total Copayment",
//             deductionAmount: settlementData.policy_wording_document.total_copayment,
//             reason: settlementData.policy_wording_document.information.total_copayment.reason
//           },
//           ...settlementData.nonpayables.categories.items_non_payable_but_billed_in_the_bill.map((item) => ({
//             name: item.item_name,
//             deductionAmount: item.value,
//             reason: item.reason
//           })),
//           ...settlementData.tariff.necessary_items.map((item) => ({
//             name: item.item_name,
//             deductionAmount: item.billed_amount - item.price,
//             reason: item.justification
//           })),
//           ...settlementData.tariff.unnecessary_items.map((item) => ({
//             name: item.item_name,
//             deductionAmount: item.billed_amount,
//             reason: item.justification
//           }))
//         ];
//         setDiscrepancyData(discrepancyArray);

//         const claimAmountResponse = await fetch('http://localhost:5000/claim-amounts');
//         if (!claimAmountResponse.ok) {
//           throw new Error(`HTTP error! status: ${claimAmountResponse.status}`);
//         }
//         const claimAmountData = await claimAmountResponse.json();
//         setClaimedAmount(claimAmountData.initial_total_net_amount);

//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };

//     fetchData();
//   }, []);

//   const downloadPDF = () => {
//     const doc = new jsPDF({
//       encoding: "UTF-8"
//     });

//     doc.setFontSize(12);
//     doc.setLineHeightFactor(1.5);

//     doc.text("Settlement Letter", 14, 20);
//     doc.text(`Date: ${new Date().toLocaleDateString()}`, 14, 30);
//     doc.text(`To: ${recipientName}`, 14, 40);
//     doc.text(`${recipientAddress}`, 14, 50);
//     doc.text(`Claim Reference Number: ${claimReferenceNumber}`, 14, 60);

//     const part1 = `Claim bearing No ${claimReferenceNumber} against the policy issued by our company has been settled for `;
//     const part2 = `₹${settlementData.ice_authorized_amount} against the amount claimed for ₹${claimedAmount}. `;
//     const part3 = "The details of the payment are as follows:";

//     doc.text(part1, 14, 70);
//     doc.text(part2, 14, 80);
//     doc.text(part3, 14, 90);

//     doc.autoTable({
//       startY: 100,
//       head: [['S.No', 'Name of the Item', 'Deduction Amount (INR)', 'Reason']],
//       body: discrepancyData.map((item, index) => [
//         index + 1,
//         item.name,
//         item.deductionAmount,
//         item.reason,
//       ]),
//       styles: {
//         fontSize: 10,
//         cellPadding: 3,
//         overflow: 'linebreak',
//       },
//       columnStyles: {
//         0: { cellWidth: 20 },
//         1: { cellWidth: 40 },
//         2: { cellWidth: 30 },
//         3: { cellWidth: 80 },
//       },
//     });

//     doc.save('ice_settlement_letter.pdf');
//   };

//   const triggerPDFGeneration = async () => {
//     console.log("PDF Generation triggered");
//     downloadPDF();
//   };

//   if (!settlementData || claimedAmount === null) {
//     return <p>Loading...</p>;
//   }

//   return (
//     <div className="settlement-container">
//       <header className="settlement-header">
//         <div className="header-content">
//           <img src="/logonew.svg" alt="Company Logo" className="company-logo" />
//           <div className="claim-info">
//             <p>Claim Reference Number: <strong>{claimReferenceNumber}</strong></p>
//             <p>(Please quote this reference number in all future correspondence)</p>
//           </div>
//         </div>
//       </header>

//       <main className="settlement-main">
//         <p>Date: {new Date().toLocaleDateString()}</p>
//         <p>To,<br />
//           {recipientName},<br />
//           {recipientAddress}
//         </p>

//         <p>Dear Partner,</p>

//         <p>
//           {`Claim bearing No ${claimReferenceNumber} against the policy issued by our company has been settled for ₹ ${settlementData.ice_authorized_amount} against the amount claimed for ₹ ${claimedAmount}. `}
//           The details of the payment are as follows:
//         </p>

//         <section className="section">
//           <h3>Detailed Working Sheet for Deductions</h3>
//           <table className="settlement-table">
//             <thead>
//               <tr>
//                 <th>S.No</th>
//                 <th>Name of the Item</th>
//                 <th>Deduction Amount (INR)</th>
//                 <th>Reason</th>
//               </tr>
//             </thead>
//             <tbody>
//               {discrepancyData && discrepancyData.map((item, index) => (
//                 <tr key={index}>
//                   <td>{index + 1}</td>
//                   <td>{item.name}</td>
//                   <td>{item.deductionAmount}</td>
//                   <td>{item.reason}</td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </section>
      
//         <button onClick={triggerPDFGeneration}>Download PDF</button>
//       </main>
//     </div>
//   );
// }

// export default SettlementLetter;



// working script for pdf generation 

// import React, { useEffect, useState } from 'react';
// import './SettlementLetter.css';
// import jsPDF from 'jspdf';
// import 'jspdf-autotable';
// import io from 'socket.io-client';

// const SettlementLetter = ({
//   recipientName,
//   recipientAddress,
//   claimReferenceNumber
// }) => {
//   const [settlementData, setSettlementData] = useState(null);
//   const [discrepancyData, setDiscrepancyData] = useState([]);
//   const [claimedAmount, setClaimedAmount] = useState(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const settlementResponse = await fetch('http://localhost:5000/policy-status');
//         if (!settlementResponse.ok) {
//           throw new Error(`HTTP error! status: ${settlementResponse.status}`);
//         }
        
//         const settlementData = await settlementResponse.json();
//         setSettlementData(settlementData);

//         const discrepancyArray = [
//           {
//             name: "Sub Limit",
//             deductionAmount: settlementData.policy_wording_document.sub_limit,
//             reason: settlementData.policy_wording_document.information.sub_limit.reason
//           },
//           {
//             name: "Proportional Deduction",
//             deductionAmount: settlementData.policy_wording_document.proportional_deduction,
//             reason: settlementData.policy_wording_document.information.proportional_deduction.reason
//           },
//           {
//             name: "Total Deductible",
//             deductionAmount: settlementData.policy_wording_document.total_deductible,
//             reason: settlementData.policy_wording_document.information.total_deductible.reason
//           },
//           {
//             name: "Total Copayment",
//             deductionAmount: settlementData.policy_wording_document.total_copayment,
//             reason: settlementData.policy_wording_document.information.total_copayment.reason
//           },
//           ...settlementData.nonpayables.categories.items_non_payable_but_billed_in_the_bill.map((item) => ({
//             name: item.item_name,
//             deductionAmount: item.value,
//             reason: item.reason
//           })),
//           ...settlementData.tariff.necessary_items.map((item) => ({
//             name: item.item_name,
//             deductionAmount: item.billed_amount - item.price,
//             reason: item.justification
//           })),
//           ...settlementData.tariff.unnecessary_items.map((item) => ({
//             name: item.item_name,
//             deductionAmount: item.billed_amount,
//             reason: item.justification
//           }))
//         ];
//         setDiscrepancyData(discrepancyArray);

//         const claimAmountResponse = await fetch('http://localhost:5000/claim-amounts');
//         if (!claimAmountResponse.ok) {
//           throw new Error(`HTTP error! status: ${claimAmountResponse.status}`);
//         }
//         const claimAmountData = await claimAmountResponse.json();
//         setClaimedAmount(claimAmountData.initial_total_net_amount);

//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };

//     fetchData();

//     // Setup WebSocket connection
//     const socket = io('http://localhost:5000');

//     // Listen for the "generate-pdf" event from the server
//     socket.on('generate-pdf', () => {
//       console.log("Received request to generate PDF");
//       generateAndUploadPDF();
//     });

//     return () => {
//       socket.disconnect();
//     };
//   }, []);

//   const generateAndUploadPDF = async () => {
//     if (!settlementData || claimedAmount === null) {
//       console.error('Data is not ready yet!');
//       return;
//     }

//     const doc = new jsPDF({
//       encoding: "UTF-8"
//     });
  
//     doc.setFontSize(12);
//     doc.setLineHeightFactor(1.5);
  
//     doc.text("Settlement Letter", 14, 20);
//     doc.text(`Date: ${new Date().toLocaleDateString()}`, 14, 30);
//     doc.text(`To: ${recipientName}`, 14, 40);
//     doc.text(`${recipientAddress}`, 14, 50);
//     doc.text(`Claim Reference Number: ${claimReferenceNumber}`, 14, 60);
  
//     const part1 = `Claim bearing No ${claimReferenceNumber} against the policy issued by our company has been settled for `;
//     const part2 = `₹${settlementData.ice_authorized_amount} against the amount claimed for ₹${claimedAmount}. `;
//     const part3 = "The details of the payment are as follows:";
  
//     doc.text(part1, 14, 70);
//     doc.text(part2, 14, 80);
//     doc.text(part3, 14, 90);
  
//     doc.autoTable({
//       startY: 100,
//       head: [['S.No', 'Name of the Item', 'Deduction Amount (INR)', 'Reason']],
//       body: discrepancyData.map((item, index) => [
//         index + 1,
//         item.name,
//         item.deductionAmount,
//         item.reason,
//       ]),
//       styles: {
//         fontSize: 10,
//         cellPadding: 3,
//         overflow: 'linebreak',
//       },
//       columnStyles: {
//         0: { cellWidth: 20 },
//         1: { cellWidth: 40 },
//         2: { cellWidth: 30 },
//         3: { cellWidth: 80 },
//       },
//     });
  
//     // Convert the PDF to a Blob
//     const pdfBlob = doc.output('blob');
  
//     // Send the Blob to the FastAPI server
//     const formData = new FormData();
//     formData.append('file', pdfBlob, `ice_settlement_letter.pdf`);
  
//     try {
//       const response = await fetch('http://localhost:5000/upload-pdf', {
//         method: 'POST',
//         body: formData
//       });
  
//       if (response.ok) {
//         console.log('PDF successfully uploaded');
//       } else {
//         console.error('PDF upload failed');
//       }
//     } catch (error) {
//       console.error('Error uploading PDF:', error);
//     }
//   };
  
//   return (
//     <div className="settlement-container">
//       <header className="settlement-header">
//         <div className="header-content">
//           <img src="/logonew.svg" alt="Company Logo" className="company-logo" />
//           <div className="claim-info">
//             <p>Claim Reference Number: <strong>{claimReferenceNumber}</strong></p>
//             <p>(Please quote this reference number in all future correspondence)</p>
//           </div>
//         </div>
//       </header>
  
//       <main className="settlement-main">
//         <p>Date: {new Date().toLocaleDateString()}</p>
//         <p>To,<br />
//           {recipientName},<br />
//           {recipientAddress}
//         </p>
  
//         <p>Dear Partner,</p>
  
//         {settlementData ? (
//           <>
//             <p>
//               {`Claim bearing No ${claimReferenceNumber} against the policy issued by our company has been settled for ₹ ${settlementData.ice_authorized_amount} against the amount claimed for ₹ ${claimedAmount}. `}
//               The details of the payment are as follows:
//             </p>
  
//             <section className="section">
//               <h3>Detailed Working Sheet for Deductions</h3>
//               <table className="settlement-table">
//                 <thead>
//                   <tr>
//                     <th>S.No</th>
//                     <th>Name of the Item</th>
//                     <th>Deduction Amount (INR)</th>
//                     <th>Reason</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {discrepancyData && discrepancyData.map((item, index) => (
//                     <tr key={index}>
//                       <td>{index + 1}</td>
//                       <td>{item.name}</td>
//                       <td>{item.deductionAmount}</td>
//                       <td>{item.reason}</td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             </section>
//           </>
//         ) : (
//           <p>Loading settlement data...</p>
//         )}
//       </main>
//     </div>
//   );
  
// }

// export default SettlementLetter;














import React, { useEffect, useState } from 'react';
import './SettlementLetter.css';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import io from 'socket.io-client';
import { API_ENDPOINTS } from '../config';

const SettlementLetter = ({
  recipientName,
  recipientAddress,
  claimReferenceNumber
}) => {
  const [settlementData, setSettlementData] = useState(null);
  const [discrepancyData, setDiscrepancyData] = useState([]);
  const [claimedAmount, setClaimedAmount] = useState(null);
  const [dataReady, setDataReady] = useState(false); // State to track when data is fully loaded

  useEffect(() => {
    const fetchData = async () => {
      try {
        const settlementResponse = await fetch(`${API_ENDPOINTS.PYTHON_SERVICE}/policy-status`);
        if (!settlementResponse.ok) {
          throw new Error(`HTTP error! status: ${settlementResponse.status}`);
        }
        
        const settlementData = await settlementResponse.json();
        setSettlementData(settlementData);

        const discrepancyArray = [
          {
            name: "Sub Limit",
            deductionAmount: settlementData.policy_wording_document.sub_limit,
            reason: settlementData.policy_wording_document.information.sub_limit.reason
          },
          {
            name: "Proportional Deduction",
            deductionAmount: settlementData.policy_wording_document.proportional_deduction,
            reason: settlementData.policy_wording_document.information.proportional_deduction.reason
          },
          {
            name: "Total Deductible",
            deductionAmount: settlementData.policy_wording_document.total_deductible,
            reason: settlementData.policy_wording_document.information.total_deductible.reason
          },
          {
            name: "Total Copayment",
            deductionAmount: settlementData.policy_wording_document.total_copayment,
            reason: settlementData.policy_wording_document.information.total_copayment.reason
          },
          ...settlementData.nonpayables.categories.items_non_payable_but_billed_in_the_bill.map((item) => ({
            name: item.item_name,
            deductionAmount: item.value,
            reason: item.reason
          })),
          ...settlementData.tariff.necessary_items.map((item) => ({
            name: item.item_name,
            deductionAmount: item.billed_amount - item.price,
            reason: item.justification
          })),
          ...settlementData.tariff.unnecessary_items.map((item) => ({
            name: item.item_name,
            deductionAmount: item.billed_amount,
            reason: item.justification
          }))
        ];
        setDiscrepancyData(discrepancyArray);

        const claimAmountResponse = await fetch(`${API_ENDPOINTS.PYTHON_SERVICE}/claim-amounts`);
        if (!claimAmountResponse.ok) {
          throw new Error(`HTTP error! status: ${claimAmountResponse.status}`);
        }
        const claimAmountData = await claimAmountResponse.json();
        setClaimedAmount(claimAmountData.initial_total_net_amount);

        setDataReady(true); // Set data ready state when data is fully loaded

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    // Setup WebSocket connection
    const socket = io(`${API_ENDPOINTS.PYTHON_SERVICE}`);

    // Listen for the "generate-pdf" event from the server
    socket.on('generate-pdf', () => {
      console.log("Received request to generate PDF");
      if (dataReady) {
        generateAndUploadPDF();
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [dataReady]);

  const generateAndUploadPDF = async () => {
    const doc = new jsPDF({
      encoding: "UTF-8"
    });

    doc.setFontSize(12);
    doc.setLineHeightFactor(1.5);

    doc.text("Settlement Letter", 14, 20);
    doc.text(`Date: ${new Date().toLocaleDateString()}`, 14, 30);
    doc.text(`Claim Reference Number: ${claimReferenceNumber}`, 14, 40);

    const part1 = `Claim bearing No ${claimReferenceNumber} against the policy issued by our company has been settled for `;
    const part2 = `Rs ${settlementData.ice_authorized_amount} against the amount claimed for Rs ${claimedAmount}. `;
    const part3 = "The details of the deductions are as follows:";

    doc.text(part1, 14, 60);
    doc.text(part2, 14, 70);
    doc.text(part3, 14, 80);

    doc.autoTable({
      startY: 100,
      head: [['S.No', 'Name of the Item', 'Deduction Amount (INR)', 'Reason']],
      body: discrepancyData.map((item, index) => [
        index + 1,
        item.name,
        item.deductionAmount,
        item.reason,
      ]),
      styles: {
        fontSize: 10,
        cellPadding: 3,
        overflow: 'linebreak',
      },
      columnStyles: {
        0: { cellWidth: 20 },
        1: { cellWidth: 40 },
        2: { cellWidth: 30 },
        3: { cellWidth: 80 },
      },
    });

    // Convert the PDF to a Blob
    const pdfBlob = doc.output('blob');

    // Send the Blob to the FastAPI server
    const formData = new FormData();
    formData.append('file', pdfBlob, `ice_settlement_letter.pdf`);

    try {
      const response = await fetch(`${API_ENDPOINTS.PYTHON_SERVICE}/upload-pdf`, {
        method: 'POST',
        body: formData
      });

      if (response.ok) {
        console.log('PDF successfully uploaded');
      } else {
        console.error('PDF upload failed');
      }
    } catch (error) {
      console.error('Error uploading PDF:', error);
    }
  };
  
  return (
    <div className="settlement-container">
      <header className="settlement-header">
        <div className="header-content">
          <img src="/logonew.svg" alt="Company Logo" className="company-logo" />
          <div className="claim-info">
            <p>Claim Reference Number: <strong>{claimReferenceNumber}</strong></p>
            <p>(Please quote this reference number in all future correspondence)</p>
          </div>
        </div>
      </header>
  
      <main className="settlement-main">
        <p>Date: {new Date().toLocaleDateString()}</p>
        <p>To,<br />
          {recipientName},<br />
          {recipientAddress}
        </p>
  
        <p>Dear Partner,</p>
  
        {settlementData ? (
          <>
            <p>
              {`Claim bearing No ${claimReferenceNumber} against the policy issued by our company has been settled for ₹ ${settlementData.ice_authorized_amount} against the amount claimed for ₹ ${claimedAmount}. `}
              The details of the payment are as follows:
            </p>
  
            <section className="section">
              <h3>Detailed Working Sheet for Deductions</h3>
              <table className="settlement-table">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Name of the Item</th>
                    <th>Deduction Amount (INR)</th>
                    <th>Reason</th>
                  </tr>
                </thead>
                <tbody>
                  {discrepancyData && discrepancyData.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.name}</td>
                      <td>{item.deductionAmount}</td>
                      <td>{item.reason}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </section>
          </>
        ) : (
          <p>Loading settlement data...</p>
        )}
      </main>
    </div>
  );
  
}

export default SettlementLetter;
