import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import "./AnimationPage.css";

function DummyAnimationPage() {
  const [steps, setSteps] = useState([false, false, false, false, false]);
  const [progress, setProgress] = useState(0);
  const [responseData, setResponseData] = useState(null);
  const [currentAnimationIndex, setCurrentAnimationIndex] = useState(0); // Track which animation to show
  const navigate = useNavigate();

  const animations = [
    "https://lottie.host/3fea76e0-e3e0-49f7-acc9-22d109283b28/wc003s0mwI.json", // First animation URL
    "https://lottie.host/108ac2ee-a698-41de-bd96-adf405839e07/ZVcciN37eg.json", // Second animation URL
  ];

  useEffect(() => {
    const fetchData = async () => {
      const url1 = `https://jsonplaceholder.typicode.com/posts/1`; // Mock API 1
      const url2 = `https://jsonplaceholder.typicode.com/posts/2`; // Mock API 2

      console.log("Making GET request to URL 1:", url1);

      try {
        const response1 = await fetch(url1, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response1.ok) {
          const data1 = await response1.json();
          console.log("API call 1 successful:", data1);
          setSteps((prevSteps) => {
            const newSteps = [...prevSteps];
            newSteps[0] = true;
            newSteps[1] = true; // Mark the second step as completed
            setProgress(45); // Move to 45% after the first two steps
            return newSteps;
          });

          console.log("Making GET request to URL 2:", url2);

          const response2 = await fetch(url2, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          });

          if (response2.ok) {
            const data2 = await response2.json();
            console.log("API call 2 successful:", data2);
            handleApiResponse({ data1, data2 });
          } else {
            console.error("Failed to process request 2:", response2.statusText);
          }
        } else {
          console.error("Failed to process request 1:", response1.statusText);
        }
      } catch (error) {
        console.error("Error in fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleApiResponse = (data) => {
    // Process the API response data here
    setResponseData(data);
    console.log("Processing response data:", data);

    // Start the animation steps
    startAnimation();
  };

  const startAnimation = () => {
    let stepIndex = 2; // Start from 2 since the first two steps are already completed
    const interval = setInterval(() => {
      setSteps((prevSteps) => {
        const newSteps = [...prevSteps];
        newSteps[stepIndex] = true;
        // Update progress based on step index
        if (stepIndex === 2) {
          setProgress(60); // 60% after third step
        } else if (stepIndex === 3) {
          setProgress(80); // 80% after fourth step
        } else if (stepIndex === 4) {
          setProgress(100); // 100% after fifth step
        }
        stepIndex += 1;
        if (stepIndex === newSteps.length) {
          clearInterval(interval);
          setTimeout(() => {
            navigate("/dummy", { state: { responseData } });
          }, 500);
        }
        return newSteps;
      });
    }, 1000);
  };

  useEffect(() => {
    const toggleInterval = setInterval(() => {
      setCurrentAnimationIndex((prevIndex) => (prevIndex + 1) % animations.length);
    }, 5000); // Switch animation every 5 seconds

    return () => clearInterval(toggleInterval); // Clear interval on component unmount
  }, [animations.length]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    path: animations[currentAnimationIndex], // Use the current animation URL
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="animation-page">
      <header>
        <h1>Initiating claim review</h1>
      </header>
      <div className="lottie-container">
        <Lottie key={currentAnimationIndex} options={defaultOptions} height={100} width={100} />
      </div>
      <div className="progress-bar">
        <div className="progress" style={{ width: `${progress}%` }}></div>
      </div>
      <ul className="animation-list">
        {[
          "Verifying member eligibility.....",
          "Analysing policy details.....",
          "Comparing with Standard Treatment Guidelines...",
          "Reviewing Bills, Tariffs, & MOUs...",
          "Detecting Potential Fraud..."
        ].map((task, index) => (
          <li key={index}>
            <span>{task}</span>
            <span className={`checkmark ${steps[index] ? "checked" : ""}`}>✔</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default DummyAnimationPage;
