// import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
// import Header from './Header';
// import './ClaimDetailsPage.css';
// import { ReactComponent as DocumentIcon } from '../icons/documentIcon.svg';  // Ensure this import is correct

// const tickImage = process.env.PUBLIC_URL + '/tick.png';
// const crossImage = process.env.PUBLIC_URL + '/cross.png';

// function ClaimDetailsPage() {
//   const [validationResults, setValidationResults] = useState(null);
//   const [files, setFiles] = useState([]);
//   const [selectedDocId, setSelectedDocId] = useState(null); // Initial state is null
//   const [patientId, setPatientId] = useState('');

//   useEffect(() => {
//     const fetchPatientId = async () => {
//       try {
//         const response = await fetch('http://localhost:8000/patient-id');
//         const data = await response.json();
//         setPatientId(data.patientId);
//       } catch (error) {
//         console.error('Error fetching patient ID:', error);
//       }
//     };

//     fetchPatientId();
//   }, []);

//   useEffect(() => {
//     const fetchValidationResults = async () => {
//       const url = 'http://localhost:5000/get_processed_data';
//       try {
//         const response = await fetch(url, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json'
//           }
//         });

//         if (response.ok) {
//           const data = await response.json();
//           setValidationResults(data.validation_results);
//         } else {
//           console.error('Failed to fetch validation results', await response.text());
//         }
//       } catch (error) {
//         console.error('Error fetching validation results:', error);
//       }
//     };

//     fetchValidationResults();
//   }, []);

//   useEffect(() => {
//     if (!patientId) return;

//     const fetchFiles = async () => {
//       const url = `http://localhost:8000/files/${patientId}`;
//       try {
//         const response = await fetch(url, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json'
//           }
//         });

//         if (response.ok) {
//           const data = await response.json();
//           setFiles(data);
//         } else {
//           console.error('Failed to fetch files', await response.text());
//         }
//       } catch (error) {
//         console.error('Error fetching files:', error);
//       }
//     };

//     fetchFiles();
//   }, [patientId]);

//   const toTitleCase = (str) => {
//     return str.replace(/_/g, ' ').replace(/\w\S*/g, (txt) => {
//       // Special case for 'stg'
//       if (txt.toLowerCase() === 'stg') {
//         return 'STG';
//       }
//       return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
//     });
//   };

//   if (!validationResults) {
//     return <div>Loading...</div>;
//   }

//   const documentStatus = Object.keys(validationResults).map(doc => {
//     const discrepancies = validationResults[doc].validity === "invalid" ? validationResults[doc].reason.length : 0;
//     const validity = validationResults[doc].validity === "valid" ? "approve" : "deny";
//     const statusImage = validity === "approve" ? tickImage : crossImage;
//     const displayText = toTitleCase(doc);
//     const reasons = validationResults[doc].reason.join("\n");

//     const linkTo = doc === 'tariff_document' ? `/tariff/${doc}` : `/document/${doc}`;

//     return (
//       <Link to={linkTo} key={doc} className="result-item" onClick={() => setSelectedDocId(doc)}>
//         <p>{displayText} {discrepancies > 0 && <span className="discrepancies">[{discrepancies}]</span>}</p>
//         <img src={statusImage} alt={validity} className="status-image" />
//         {discrepancies > 0 && <div className="tooltip">{reasons}</div>}
//       </Link>
//     );
//   });

//   const documentLinks = files.map(file => {
//     const fileName = file.split('\\').pop(); // or .split('/').pop() depending on your environment
//     return (
//       <li key={file}>
//         <a href={`http://localhost:8000/uploads/patients/${patientId}/${file}`} target="_blank" rel="noopener noreferrer">
//           <DocumentIcon className="document-icon" />
//           {fileName}
//         </a>
//       </li>
//     );
//   });

//   return (
//     <div className="claim-details-page">
//       <Header showDisclaimer={false} showSearch={false} fetchResponseText={false} docId={selectedDocId} /> {/* Pass docId */}
//       <div className="details-section">
//         <div className="audit-results">
//           {documentStatus}
//         </div>
//         <div className="documents">
//           <ul>
//             {documentLinks}
//           </ul>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default ClaimDetailsPage;







// import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
// import Header from './Header';
// import './ClaimDetailsPage.css';
// import { ReactComponent as DocumentIcon } from '../icons/documentIcon.svg';  // Ensure this import is correct

// const tickImage = process.env.PUBLIC_URL + '/tick.png';
// const crossImage = process.env.PUBLIC_URL + '/cross.png';

// function ClaimDetailsPage() {
//   const [validationResults, setValidationResults] = useState(null);
//   const [files, setFiles] = useState([]);
//   const [selectedDocId, setSelectedDocId] = useState(null); // Initial state is null
//   const [patientId, setPatientId] = useState('');
//   const [isBlacklisted, setIsBlacklisted] = useState(false); // State for blacklisted status

//   useEffect(() => {
//     const fetchPatientId = async () => {
//       try {
//         const response = await fetch('http://localhost:8000/patient-id');
//         const data = await response.json();
//         setPatientId(data.patientId);
//       } catch (error) {
//         console.error('Error fetching patient ID:', error);
//       }
//     };

//     fetchPatientId();
//   }, []);

//   useEffect(() => {
//     const fetchClaimData = async () => {
//       try {
//         const response = await fetch('http://localhost:5000/claim-data');
//         const data = await response.json();
//         setIsBlacklisted(data.blacklisted_hospital === "True"); // Check for blacklisted status
//       } catch (error) {
//         console.error('Error fetching claim data:', error);
//       }
//     };

//     fetchClaimData();
//   }, []);

//   useEffect(() => {
//     const fetchValidationResults = async () => {
//       const url = 'http://localhost:5000/get_processed_data';
//       try {
//         const response = await fetch(url, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json'
//           }
//         });

//         if (response.ok) {
//           const data = await response.json();
//           setValidationResults(data.validation_results);
//         } else {
//           console.error('Failed to fetch validation results', await response.text());
//         }
//       } catch (error) {
//         console.error('Error fetching validation results:', error);
//       }
//     };

//     fetchValidationResults();
//   }, []);

//   useEffect(() => {
//     if (!patientId) return;

//     const fetchFiles = async () => {
//       const url = `http://localhost:8000/files/${patientId}`;
//       try {
//         const response = await fetch(url, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json'
//           }
//         });

//         if (response.ok) {
//           const data = await response.json();
//           setFiles(data);
//         } else {
//           console.error('Failed to fetch files', await response.text());
//         }
//       } catch (error) {
//         console.error('Error fetching files:', error);
//       }
//     };

//     fetchFiles();
//   }, [patientId]);

//   const toTitleCase = (str) => {
//     return str.replace(/_/g, ' ').replace(/\w\S*/g, (txt) => {
//       // Special case for 'stg'
//       if (txt.toLowerCase() === 'stg') {
//         return 'STG';
//       }
//       return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
//     });
//   };

//   if (!validationResults) {
//     return <div>Loading...</div>;
//   }

//   const documentStatus = Object.keys(validationResults).map(doc => {
//     const discrepancies = validationResults[doc].validity === "invalid" ? validationResults[doc].reason.length : 0;
//     const validity = validationResults[doc].validity === "valid" ? "approve" : "deny";
//     const statusImage = validity === "approve" ? tickImage : crossImage;
//     const displayText = toTitleCase(doc);
//     const reasons = validationResults[doc].reason.join("\n");

//     return (
//       <div key={doc} className={`result-item ${isBlacklisted ? 'frozen' : ''}`}>
//         <p>{displayText} {discrepancies > 0 && <span className="discrepancies">[{discrepancies}]</span>}</p>
//         <img src={statusImage} alt={validity} className="status-image" />
//         {discrepancies > 0 && <div className="tooltip">{reasons}</div>}
//       </div>
//     );
//   });

//   const documentLinks = files.map(file => {
//     const fileName = file.split('\\').pop(); // or .split('/').pop() depending on your environment
//     return (
//       <li key={file}>
//         {/* Disable links if blacklisted */}
//         {isBlacklisted ? (
//           <span className="frozen-document">
//             <DocumentIcon className="document-icon" />
//             {fileName}
//           </span>
//         ) : (
//           <a href={`http://localhost:8000/uploads/patients/${patientId}/${file}`} target="_blank" rel="noopener noreferrer">
//             <DocumentIcon className="document-icon" />
//             {fileName}
//           </a>
//         )}
//       </li>
//     );
//   });

//   return (
//     <div className="claim-details-page">
//       <Header showDisclaimer={false} showSearch={false} fetchResponseText={false} docId={selectedDocId} /> {/* Pass docId */}
      
//       {/* Display warning popup if blacklisted */}
//       {isBlacklisted && (
//         <div className="warning-popup">
//           <p>⚠️ Warning: The hospital associated with this claim is blacklisted.</p>
//         </div>
//       )}
      
//       <div className="details-section">
//         <div className={`audit-results ${isBlacklisted ? 'frozen' : ''}`}>
//           {documentStatus}
//         </div>
//         <div className="documents">
//           <ul>
//             {documentLinks}
//           </ul>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default ClaimDetailsPage;



// import React, { useEffect, useState } from 'react';
// import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate
// import Header from './Header';
// import './ClaimDetailsPage.css';
// import { ReactComponent as DocumentIcon } from '../icons/documentIcon.svg';  // Ensure this import is correct

// const tickImage = process.env.PUBLIC_URL + '/tick.png';
// const crossImage = process.env.PUBLIC_URL + '/cross.png';

// function ClaimDetailsPage() {
//   const [validationResults, setValidationResults] = useState(null);
//   const [files, setFiles] = useState([]);
//   const [selectedDocId, setSelectedDocId] = useState(null); // Initial state is null
//   const [patientId, setPatientId] = useState('');
//   const [isBlacklisted, setIsBlacklisted] = useState(false); // State for blacklisted status
//   const navigate = useNavigate(); // Use useNavigate for navigation

//   useEffect(() => {
//     const fetchPatientId = async () => {
//       try {
//         const response = await fetch('http://localhost:8000/patient-id');
//         const data = await response.json();
//         setPatientId(data.patientId);
//       } catch (error) {
//         console.error('Error fetching patient ID:', error);
//       }
//     };

//     fetchPatientId();
//   }, []);

//   useEffect(() => {
//     const fetchClaimData = async () => {
//       try {
//         const response = await fetch('http://localhost:5000/claim-data');
//         const data = await response.json();
//         setIsBlacklisted(data.blacklisted_hospital === "True"); // Check for blacklisted status
//       } catch (error) {
//         console.error('Error fetching claim data:', error);
//       }
//     };

//     fetchClaimData();
//   }, []);

//   useEffect(() => {
//     const fetchValidationResults = async () => {
//       const url = 'http://localhost:5000/get_processed_data';
//       try {
//         const response = await fetch(url, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json'
//           }
//         });

//         if (response.ok) {
//           const data = await response.json();
//           setValidationResults(data.validation_results);
//         } else {
//           console.error('Failed to fetch validation results', await response.text());
//         }
//       } catch (error) {
//         console.error('Error fetching validation results:', error);
//       }
//     };

//     fetchValidationResults();
//   }, []);

//   useEffect(() => {
//     if (!patientId) return;

//     const fetchFiles = async () => {
//       const url = `http://localhost:8000/files/${patientId}`;
//       try {
//         const response = await fetch(url, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json'
//           }
//         });

//         if (response.ok) {
//           const data = await response.json();
//           setFiles(data);
//         } else {
//           console.error('Failed to fetch files', await response.text());
//         }
//       } catch (error) {
//         console.error('Error fetching files:', error);
//       }
//     };

//     fetchFiles();
//   }, [patientId]);

//   const toTitleCase = (str) => {
//     return str.replace(/_/g, ' ').replace(/\w\S*/g, (txt) => {
//       // Special case for 'stg'
//       if (txt.toLowerCase() === 'stg') {
//         return 'STG';
//       }
//       return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
//     });
//   };

//   if (!validationResults) {
//     return <div>Loading...</div>;
//   }

//   const handleItemClick = (doc) => {
//     if (!isBlacklisted) {
//       setSelectedDocId(doc);
//       navigate(`/document/${doc}`);
//     }
//   };

//   const nameMappings = {
//     policy_document: 'Enrollment',
//     policy_wording_document: 'Policy Wording',
//     stg_document: 'Standard Treatment Guidelines',
//     tariff_document: 'Billing',
//   };
  
//   const documentStatus = Object.keys(validationResults).map((doc) => {
//     const discrepancies =
//       validationResults[doc].validity === "invalid"
//         ? validationResults[doc].reason.length
//         : 0;
//     const validity =
//       validationResults[doc].validity === "valid" ? "approve" : "deny";
//     const statusImage = validity === "approve" ? tickImage : crossImage;
//     const displayText = nameMappings[doc] || toTitleCase(doc); // Use mapped name or fallback to the original toTitleCase function
//     const reasons = validationResults[doc].reason.join("\n");
//     // const linkTo =
//     //   doc === "tariff_document" ? `/tariff/${doc}` : `/document/${doc}`;
//     const linkTo = `/document/${doc}`;
//     const isBlacklisted = false; // Update this based on your logic
  
//     return (
//       <div
//         key={doc}
//         className={`result-item ${isBlacklisted ? "frozen" : ""}`}
//         onClick={() => handleItemClick(doc)} // Handle item click
//       >
//         <Link
//           to={linkTo}
//           className="result-item-link"
//           onClick={(e) => {
//             e.stopPropagation(); // Prevent parent div click event
//             setSelectedDocId(doc);
//           }}
//         >
//           <p>
//             {displayText}{" "}
//             {discrepancies > 0 && (
//               <span className="discrepancies">[{discrepancies}]</span>
//             )}
//           </p>
//         </Link>
//         <img src={statusImage} alt={validity} className="status-image" />
//         {discrepancies > 0 && <div className="tooltip">{reasons}</div>}
//       </div>
//     );
//   });
  

//   const documentLinks = files.map(file => {
//     const fileName = file.split('\\').pop(); // or .split('/').pop() depending on your environment
//     return (
//       <li key={file}>
//         {/* Disable links if blacklisted */}
//         {isBlacklisted ? (
//           <span className="frozen-document">
//             <DocumentIcon className="document-icon" />
//             {fileName}
//           </span>
//         ) : (
//           <a href={`http://localhost:8000/uploads/patients/${patientId}/${file}`} target="_blank" rel="noopener noreferrer">
//             <DocumentIcon className="document-icon" />
//             {fileName}
//           </a>
//         )}
//       </li>
//     );
//   });

//   return (
//     <div className="claim-details-page">
//       <Header showDisclaimer={false} showSearch={false} fetchResponseText={false} docId={selectedDocId} /> {/* Pass docId */}
      
//       {/* Display warning popup if blacklisted */}
//       {isBlacklisted && (
//         <div className="warning-popup"style={{  fontSize: '20px', fontWeight:"500" }}>
//           <p>⚠️ Warning: The hospital associated with this claim is blacklisted.</p>
//         </div>
//       )}
      
//       <div className="details-section">
//         <div className={`audit-results ${isBlacklisted ? 'frozen' : ''}`}>
//           {documentStatus}
//         </div>
//         <div className="documents">
//           <ul>
//             {documentLinks}
//           </ul>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default ClaimDetailsPage;


// import React, { useEffect, useState } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import Header from './Header';
// import './ClaimDetailsPage.css';
// import { ReactComponent as DocumentIcon } from '../icons/documentIcon.svg';

// const tickImage = process.env.PUBLIC_URL + '/tick.png';
// const crossImage = process.env.PUBLIC_URL + '/cross.png';

// const nameMappings = {
//   policy_document: 'Enrollment',
//   policy_wording_document: 'Policy Wording',
//   stg_document: 'Standard Treatment Guidelines',
//   tariff_document: 'Tariff',
//   nonpayables: 'Non Payables',
//   discrepancy_summary: 'Discrepancy Summary',
// };

// const toTitleCase = (str) => {
//   return str.replace(/_/g, ' ').replace(/\w\S*/g, (txt) => {
//     return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
//   });
// };

// function ClaimDetailsPage() {
//   const [validationResults, setValidationResults] = useState(null);
//   const [files, setFiles] = useState([]);
//   const [selectedDocId, setSelectedDocId] = useState(null);
//   const [patientId, setPatientId] = useState('');
//   const [isBlacklisted, setIsBlacklisted] = useState(false);
//   const [iceAuthorizedAmount, setIceAuthorizedAmount] = useState(null); // State for ICE Authorized Amount
//   const [loading, setLoading] = useState(true); // State to track loading
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchPatientId = async () => {
//       try {
//         const response = await fetch('http://localhost:8000/patient-id');
//         const data = await response.json();
//         setPatientId(data.patientId);
//       } catch (error) {
//         console.error('Error fetching patient ID:', error);
//       }
//     };

//     fetchPatientId();
//   }, []);

//   useEffect(() => {
//     const fetchClaimData = async () => {
//       try {
//         const response = await fetch('http://localhost:5000/claim-data');
//         const data = await response.json();
//         setIsBlacklisted(data.blacklisted_hospital === "True");
//       } catch (error) {
//         console.error('Error fetching claim data:', error);
//       }
//     };

//     fetchClaimData();
//   }, []);

//   useEffect(() => {
//     const fetchValidationResults = async () => {
//       const url = 'http://localhost:5000/get_processed_data';
//       try {
//         const response = await fetch(url, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json'
//           }
//         });

//         if (response.ok) {
//           const data = await response.json();
//           setValidationResults(data.validation_results);
//         } else {
//           console.error('Failed to fetch validation results', await response.text());
//         }
//       } catch (error) {
//         console.error('Error fetching validation results:', error);
//       }
//     };

//     fetchValidationResults();
//   }, []);

//   useEffect(() => {
//     if (!patientId) return;

//     const fetchFiles = async () => {
//       const url = `http://localhost:8000/files/${patientId}`;
//       try {
//         const response = await fetch(url, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json'
//           }
//         });

//         if (response.ok) {
//           const data = await response.json();
//           setFiles(data);
//         } else {
//           console.error('Failed to fetch files', await response.text());
//         }
//       } catch (error) {
//         console.error('Error fetching files:', error);
//       }
//     };

//     fetchFiles();
//   }, [patientId]);

//   const [policyStatus, setPolicyStatus] = useState(null);

// useEffect(() => {
//   const fetchPolicyStatus = async () => {
//     const url = 'http://127.0.0.1:5000/policy-status';
//     try {
//       const response = await fetch(url, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json; charset=UTF-8',
//         },
//       });

//       if (response.ok) {
//         const data = await response.json();
//         setPolicyStatus(data); // Store the policy status data
//         setIceAuthorizedAmount(data.ice_authorized_amount); // Fetch ICE Authorized Amount
//       } else {
//         console.error('Failed to fetch policy status', await response.text());
//       }
//     } catch (error) {
//       console.error('Error fetching policy status:', error);
//     } finally {
//       setLoading(false); // Stop loading after fetching policy status
//     }
//   };

//   fetchPolicyStatus();
// }, []);


//   const handleItemClick = (doc) => {
//     if (!isBlacklisted) {
//       setSelectedDocId(doc);
//       navigate(`/document/${doc}`);
//     }
//   };

//   if (loading || !validationResults) {
//     return <div>Loading...</div>;
//   }

//   const documentStatus = [
//     'discrepancy_summary',
//     ...Object.keys(validationResults),
//     'nonpayables',
//   ].map((doc) => {
//     let discrepancies = 0;
//     let tooltipText = "";  // Initialize tooltip text as empty
  
//     // Define isInvalid for each document
//     const isInvalid = validationResults[doc]?.validity === "invalid";
  
//     if (policyStatus) {
//       if (doc === 'policy_wording_document' && isInvalid) {
//         discrepancies = Object.keys(policyStatus.policy_wording_document.information || {}).length;
//       } else if (doc === 'policy_document' && isInvalid) {
//         discrepancies = 1;
//       } else if (doc === 'stg_document' && isInvalid) {
//         discrepancies = 1;
//       } else if (doc === 'tariff_document') {
//         const necessaryCount = policyStatus.tariff?.necessary_items?.length || 0;
//         const nonCompliantCount = policyStatus.tariff?.non_compliant_items?.length || 0;
//         const unnecessaryCount = policyStatus.tariff?.unnecessary_items?.length || 0;
  
//         discrepancies = necessaryCount + nonCompliantCount + unnecessaryCount;
  
//         // Determine validity based on discrepancies count
//         const validity = discrepancies === 0 ? "approve" : "deny";
//         const statusImage = validity === "approve" ? tickImage : crossImage;
  
//         if (discrepancies > 0) {
//           tooltipText = "Some items in the bill do not match the approved tariff rates.";
//         }
  
//         return (
//           <div
//             key={doc}
//             className={`result-item ${isBlacklisted ? "frozen" : ""}`}
//             onClick={() => handleItemClick(doc)}
//           >
//             <Link
//               to={`/document/${doc}`}
//               className="result-item-link"
//               onClick={(e) => {
//                 e.stopPropagation();
//                 setSelectedDocId(doc);
//               }}
//             >
//               <p>
//                 {nameMappings[doc] || toTitleCase(doc)}{" "}
//                 {discrepancies > 0 && (
//                   <span className="discrepancies">[{discrepancies}]</span>
//                 )}
//               </p>
//             </Link>
//             <img src={statusImage} alt={validity} className="status-image" />
//             {discrepancies > 0 && <div className="tooltip">{tooltipText}</div>}
//           </div>
//         );
//       } else if (doc === 'nonpayables') {
//         const nonPayablesCount = policyStatus.nonpayables?.categories?.items_non_payable_but_billed_in_the_bill?.length || 0;
  
//         discrepancies = nonPayablesCount;
  
//         // Determine validity based on discrepancies count
//         const validity = discrepancies === 0 ? "approve" : "deny";
//         const statusImage = validity === "approve" ? tickImage : crossImage;
  
//         if (discrepancies > 0) {
//           tooltipText = "The bill includes items that are not covered under the policy.";
//         }
  
//         return (
//           <div
//             key={doc}
//             className={`result-item ${isBlacklisted ? "frozen" : ""}`}
//             onClick={() => handleItemClick(doc)}
//           >
//             <Link
//               to={`/document/${doc}`}
//               className="result-item-link"
//               onClick={(e) => {
//                 e.stopPropagation();
//                 setSelectedDocId(doc);
//               }}
//             >
//               <p>
//                 {nameMappings[doc] || toTitleCase(doc)}{" "}
//                 {discrepancies > 0 && (
//                   <span className="discrepancies">[{discrepancies}]</span>
//                 )}
//               </p>
//             </Link>
//             <img src={statusImage} alt={validity} className="status-image" />
//             {discrepancies > 0 && <div className="tooltip">{tooltipText}</div>}
//           </div>
//         );
//       } else if (isInvalid) {
//         discrepancies = validationResults[doc]?.reason.length || 0;
//       }
//     } else if (isInvalid) {
//       discrepancies = validationResults[doc]?.reason.length || 0;
//     }
  
//     const validity = validationResults[doc]?.validity === "valid" ? "approve" : "deny";
//     const statusImage = validity === "approve" ? tickImage : crossImage;
//     const displayText = nameMappings[doc] || toTitleCase(doc);
//     const reasons = validationResults[doc]?.reason.join("\n") || "";
//     const linkTo = `/document/${doc}`;
  
//     const itemClass = doc === 'discrepancy_summary' ? 'result-item discrepancy-summary' : 'result-item';
  
//     return (
//       <div
//         key={doc}
//         className={`${itemClass} ${isBlacklisted ? "frozen" : ""}`}
//         onClick={() => handleItemClick(doc)}
//       >
//         <Link
//           to={linkTo}
//           className="result-item-link"
//           onClick={(e) => {
//             e.stopPropagation();
//             setSelectedDocId(doc);
//           }}
//         >
//           <p>
//             {displayText}{" "}
//             {discrepancies > 0 && (
//               <span className="discrepancies">[{discrepancies}]</span>
//             )}
//           </p>
//         </Link>
//         {validationResults[doc] && (
//           <img src={statusImage} alt={validity} className="status-image" />
//         )}
//         {discrepancies > 0 && <div className="tooltip">{tooltipText || reasons}</div>}
//       </div>
//     );
//   });
  
//   const documentLinks = files.map(file => {
//     const fileName = file.split('\\').pop();
//     return (
//       <li key={file}>
//         {isBlacklisted ? (
//           <span className="frozen-document">
//             <DocumentIcon className="document-icon" />
//             {fileName}
//           </span>
//         ) : (
//           <a href={`http://localhost:8000/uploads/patients/${patientId}/${file}`} target="_blank" rel="noopener noreferrer">
//             <DocumentIcon className="document-icon" />
//             {fileName}
//           </a>
//         )}
//       </li>
//     );
//   });

//   return (
//     <div className="claim-details-page">
//       <Header
//         showDisclaimer={false}
//         showSearch={false}
//         fetchResponseText={false}
//         docId={selectedDocId}
//         iceAuthorizedAmount={iceAuthorizedAmount} // Pass iceAuthorizedAmount to Header
//       />
      
//       {isBlacklisted && (
//         <div className="warning-popup" style={{ fontSize: '20px', fontWeight: "500" }}>
//           <p>⚠️ Warning: The hospital associated with this claim is blacklisted.</p>
//         </div>
//       )}
      
//       <div className="details-section">
//         <div className={`audit-results ${isBlacklisted ? 'frozen' : ''}`}>
//           {documentStatus}
//         </div>
//         <div className="documents">
//           <ul>
//             {documentLinks}
//           </ul>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default ClaimDetailsPage;











// import React, { useEffect, useState } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import Header from './Header';
// import './ClaimDetailsPage.css';
// import { ReactComponent as DocumentIcon } from '../icons/documentIcon.svg';
// import { API_ENDPOINTS } from '../config';

// const tickImage = process.env.PUBLIC_URL + '/tick.png';
// const crossImage = process.env.PUBLIC_URL + '/cross.png';

// const nameMappings = {
//   policy_document: 'Enrollment',
//   policy_wording_document: 'Policy Wording',
//   stg_document: 'Standard Treatment Guidelines',
//   tariff_document: 'Tariff',
//   nonpayables: 'Non Payables',
//   discrepancy_summary: 'Discrepancy Summary',
// };

// const toTitleCase = (str) => {
//   return str.replace(/_/g, ' ').replace(/\w\S*/g, (txt) => {
//     return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
//   });
// };

// function ClaimDetailsPage() {
//   const [validationResults, setValidationResults] = useState(null);
//   const [files, setFiles] = useState([]);
//   const [selectedDocId, setSelectedDocId] = useState(null);
//   const [patientId, setPatientId] = useState('');
//   const [isBlacklisted, setIsBlacklisted] = useState(false);
//   const [iceAuthorizedAmount, setIceAuthorizedAmount] = useState(null); // State for ICE Authorized Amount
//   const [loading, setLoading] = useState(true); // State to track loading
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchPatientId = async () => {
//       try {
//         const response = await fetch(`${API_ENDPOINTS.EXPRESS_SERVICE}/patient-id`);
//         const data = await response.json();
//         setPatientId(data.patientId);
//       } catch (error) {
//         console.error('Error fetching patient ID:', error);
//       }
//     };

//     fetchPatientId();
//   }, []);

//   useEffect(() => {
//     const fetchClaimData = async () => {
//       try {
//         const response = await fetch(`${API_ENDPOINTS.PYTHON_SERVICE}/claim-data`);
//         const data = await response.json();
//         setIsBlacklisted(data.blacklisted_hospital === "True");
//       } catch (error) {
//         console.error('Error fetching claim data:', error);
//       }
//     };

//     fetchClaimData();
//   }, []);

//   useEffect(() => {
//     const fetchValidationResults = async () => {
//       const url = `${API_ENDPOINTS.PYTHON_SERVICE}/get_processed_data`;
//       try {
//         const response = await fetch(url, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json'
//           }
//         });

//         if (response.ok) {
//           const data = await response.json();
//           setValidationResults(data.validation_results);
//         } else {
//           console.error('Failed to fetch validation results', await response.text());
//         }
//       } catch (error) {
//         console.error('Error fetching validation results:', error);
//       }
//     };

//     fetchValidationResults();
//   }, []);

//   useEffect(() => {
//     if (!patientId) return;

//     const fetchFiles = async () => {
//       const url = `${API_ENDPOINTS.EXPRESS_SERVICE}/files/${patientId}`;
//       try {
//         const response = await fetch(url, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json'
//           }
//         });

//         if (response.ok) {
//           const data = await response.json();
//           setFiles(data);
//         } else {
//           console.error('Failed to fetch files', await response.text());
//         }
//       } catch (error) {
//         console.error('Error fetching files:', error);
//       }
//     };

//     fetchFiles();
//   }, [patientId]);

//   const [policyStatus, setPolicyStatus] = useState(null);

// useEffect(() => {
//   const fetchPolicyStatus = async () => {
//     const url = `${API_ENDPOINTS.PYTHON_SERVICE}/policy-status`;
//     try {
//       const response = await fetch(url, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json; charset=UTF-8',
//         },
//       });

//       if (response.ok) {
//         const data = await response.json();
//         setPolicyStatus(data); // Store the policy status data
//         setIceAuthorizedAmount(data.ice_authorized_amount); // Fetch ICE Authorized Amount
//       } else {
//         console.error('Failed to fetch policy status', await response.text());
//       }
//     } catch (error) {
//       console.error('Error fetching policy status:', error);
//     } finally {
//       setLoading(false); // Stop loading after fetching policy status
//     }
//   };

//   fetchPolicyStatus();
// }, []);


//   const handleItemClick = (doc) => {
//     if (!isBlacklisted) {
//       setSelectedDocId(doc);
//       navigate(`/document/${doc}`);
//     }
//   };

//   if (loading || !validationResults) {
//     return <div>Loading...</div>;
//   }

// // Start with the initial sum of discrepancies
// let totalDiscrepancies = Object.keys(validationResults).reduce((sum, doc) => {
//   let docDiscrepancies = 0;

//   if (doc === 'tariff_document') {
//     // Always include tariff_document discrepancies
//     const necessaryCount = policyStatus?.tariff?.necessary_items?.length || 0;
//     const nonCompliantCount = policyStatus?.tariff?.non_compliant_items?.length || 0;
//     const unnecessaryCount = policyStatus?.tariff?.unnecessary_items?.length || 0;
//     docDiscrepancies = necessaryCount + nonCompliantCount + unnecessaryCount;
//   } else {
//     // Include discrepancies for other documents only if they are invalid
//     const isInvalid = validationResults[doc]?.validity === "invalid";
//     if (isInvalid) {
//       if (doc === 'policy_wording_document') {
//         docDiscrepancies = Object.keys(policyStatus.policy_wording_document.information || {}).length;
//       } else if (doc === 'stg_document') {
//         docDiscrepancies = 1;  // Assuming there's one discrepancy
//         console.log(`STG Document Discrepancies: ${docDiscrepancies}`);
//       } else {
//         docDiscrepancies = validationResults[doc]?.reason.length || 0;
//       }
//     }
//   }

//   console.log(`Document: ${doc}, Discrepancies: ${docDiscrepancies}`);
//   return sum + docDiscrepancies;
// }, 0);

// // Manually add nonpayables discrepancies
// const nonpayablesDiscrepancies = policyStatus?.nonpayables?.categories?.items_non_payable_but_billed_in_the_bill?.length || 0;
// totalDiscrepancies += nonpayablesDiscrepancies;
// console.log(`Nonpayables Discrepancies Manually Added: ${nonpayablesDiscrepancies}`);
// console.log(`Total Discrepancies Calculated: ${totalDiscrepancies}`);

// totalDiscrepancies = 0
// // Add the length of Missed Deductions to the total discrepancies
// const missedDeductionsCount = policyStatus?.Missed_Deductions?.length || 0;
// totalDiscrepancies += missedDeductionsCount;  // Add missed deductions count to the total

  
  
  
// const documentStatus = [
//   'discrepancy_summary',
//   ...Object.keys(validationResults),
//   'nonpayables',
// ].map((doc) => {
//   let discrepancies = 0;
//   let tooltipText = "";

//   let validity = "deny";  // Default validity to "deny" (cross)
//   const isInvalid = validationResults[doc]?.validity === "invalid";

//   if (policyStatus) {
//     // Logic for specific documents:
//     if (doc === 'policy_document') {
//       // If the policy document is valid, tick, otherwise cross
//       validity = policyStatus.policy_document?.status?.valid ? "approve" : "deny";
      
//       // Add discrepancy count as "1" if policy_document is invalid
//       if (validity === "deny") {
//         discrepancies = 1;  // Set 1 discrepancy if invalid
//       }
//     } 
//     else if (doc === 'policy_wording_document') {
//       const {
//         sub_limit,
//         proportional_deduction,
//         total_deductible,
//         total_copayment
//       } = policyStatus.policy_wording_document || {};
      
//       // If all these values are 0, tick, otherwise cross
//       const allZero = sub_limit === 0 && proportional_deduction === 0 && total_deductible === 0 && total_copayment === 0;
//       validity = allZero ? "approve" : "deny";

//       if (isInvalid) {
//         discrepancies = Object.keys(policyStatus.policy_wording_document.information || {}).length;
//       }
//     } 
//     else if (doc === 'stg_document') {
//       // If alignment percentage is greater than 70, tick, otherwise cross
//       const alignmentPercentage = policyStatus.stg_document?.alignment_percentage || 0;
//       validity = alignmentPercentage > 70 ? "approve" : "deny";

//       if (isInvalid) {
//         discrepancies = 1;  // Assuming one discrepancy if invalid
//       }
//     } 
//     else if (doc === 'tariff_document') {
//       const necessaryCount = policyStatus.tariff?.necessary_items?.length || 0;
//       const nonCompliantCount = policyStatus.tariff?.non_compliant_items?.length || 0;
//       const unnecessaryCount = policyStatus.tariff?.unnecessary_items?.length || 0;

//       discrepancies = necessaryCount + nonCompliantCount + unnecessaryCount;

//       // Set custom tooltip text for Tariff
//       if (discrepancies > 0) {
//         tooltipText = "Some items in the bill do not match the approved tariff rates.";
//       }
//     } 
//     else if (doc === 'nonpayables') {
//       discrepancies = policyStatus.nonpayables?.categories?.items_non_payable_but_billed_in_the_bill?.length || 0;

//       // Set custom tooltip text for Non Payables
//       if (discrepancies > 0) {
//         tooltipText = "The bill includes items that are not covered under the policy.";
//       }
//     } 
//     else if (isInvalid) {
//       discrepancies = validationResults[doc]?.reason.length || 0;
//     }

//     // Log the discrepancies calculated for this document
//     console.log(`Rendering Document: ${doc}, Discrepancies: ${discrepancies}`);
//   }

//   const statusImage = validity === "approve" ? tickImage : crossImage;

//   const displayText = doc === 'discrepancy_summary'
//     ? <>
//         {nameMappings[doc] || toTitleCase(doc)} <span style={{ color: 'red' }}>[{totalDiscrepancies}]</span>
//       </>
//     : nameMappings[doc] || toTitleCase(doc);

//   const reasons = validationResults[doc]?.reason.join("\n") || "";
//   const linkTo = `/document/${doc}`;

//   const itemClass = doc === 'discrepancy_summary' ? 'result-item discrepancy-summary' : 'result-item';

//   return (
//     <div
//       key={doc}
//       className={`${itemClass} ${isBlacklisted ? "frozen" : ""}`}
//       onClick={() => handleItemClick(doc)}
//     >
//       <Link
//         to={linkTo}
//         className="result-item-link"
//         onClick={(e) => {
//           e.stopPropagation();
//           setSelectedDocId(doc);
//         }}
//       >
//         <p>
//           {displayText}{" "}
//           {validity === "deny" && discrepancies > 0 && ( // Only show discrepancies if it's invalid
//             <span 
//               className="discrepancies" 
//               style={{ color: 'red' }}  // Apply red color to all discrepancies
//             >
//               [{discrepancies}]
//             </span>
//           )}
//         </p>
//       </Link>
//       {doc !== 'discrepancy_summary' && (
//         <img src={statusImage} alt={validity} className="status-image" />
//       )}
//       {discrepancies > 0 && <div className="tooltip">{tooltipText || reasons}</div>}
//     </div>
//   );
// });



// console.log(`Document Status Rendered with Total Discrepancies: ${totalDiscrepancies}`);


// const documentLinks = files.map(file => {
//   // const fileName = file.split('\\').pop();
//   // const fileName = file.split('/').pop();
//   const fileName = file.split(/[/\\]/).pop();
//   return (
//     <li key={file}>
//       {isBlacklisted ? (
//         <span className="frozen-document">
//           <DocumentIcon className="document-icon" />
//           {fileName}
//         </span>
//       ) : (
//         <a href={`${API_ENDPOINTS.EXPRESS_SERVICE}/uploads/patients/${patientId}/${file}`} target="_blank" rel="noopener noreferrer">
//           <DocumentIcon className="document-icon" />
//           {fileName}
//         </a>
//       )}
//     </li>
//   );
// });

// return (
//   <div className="claim-details-page">
//     <Header
//       showDisclaimer={false}
//       showSearch={false}
//       fetchResponseText={false}
//       docId={selectedDocId}
//       iceAuthorizedAmount={iceAuthorizedAmount} // Pass iceAuthorizedAmount to Header
//     />
    
//     {isBlacklisted && (
//       <div className="warning-popup" style={{ fontSize: '20px', fontWeight: "500" }}>
//         <p>⚠️ Warning: The hospital associated with this claim is blacklisted.</p>
//       </div>
//     )}
    
//     {/* New div for Discrepancy Summary */}
//     <div className="discrepancy-summary-container">
//       <div className="discrepancy-summary">
//         {documentStatus[0]} {/* This assumes the first element is the Discrepancy Summary */}
//       </div>
//     </div>

//     <div className="details-section">
      
//       <div className={`audit-results ${isBlacklisted ? 'frozen' : ''}`}>
//       <h2 className="ice-deductions-heading">ICE Deductions</h2>
//         {documentStatus.slice(1)} {/* All the other document statuses */}
//       </div>
//       <div className="documents">
      
//         <ul>
//           {documentLinks}
//         </ul>
//       </div>
//     </div>
//   </div>
// );
// }

// export default ClaimDetailsPage;




// ClaimDetailsPage.js

import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from './Header';
import './ClaimDetailsPage.css';
import { ReactComponent as DocumentIcon } from '../icons/documentIcon.svg';
import { API_ENDPOINTS } from '../config';

const tickImage = process.env.PUBLIC_URL + '/tick.png';
const crossImage = process.env.PUBLIC_URL + '/cross.png';

const nameMappings = {
  policy_document: 'Enrollment',
  policy_wording_document: 'Policy Wording',
  stg_document: 'Standard Treatment Guidelines',
  tariff_document: 'Tariff',
  nonpayables: 'Non Payables',
  discrepancy_summary: 'Discrepancy Summary',
};

const toTitleCase = (str) => {
  return str.replace(/_/g, ' ').replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

function ClaimDetailsPage() {
  const [validationResults, setValidationResults] = useState(null);
  const [files, setFiles] = useState([]);
  const [selectedDocId, setSelectedDocId] = useState(null);
  const [patientId, setPatientId] = useState('');
  const [isBlacklisted, setIsBlacklisted] = useState(false);
  const [iceAuthorizedAmount, setIceAuthorizedAmount] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [policyStatus, setPolicyStatus] = useState(null);

  useEffect(() => {
    const fetchPatientId = async () => {
      try {
        const response = await fetch(`${API_ENDPOINTS.EXPRESS_SERVICE}/patient-id`);
        const data = await response.json();
        setPatientId(data.patientId);
      } catch (error) {
        console.error('Error fetching patient ID:', error);
      }
    };

    fetchPatientId();
  }, []);

  useEffect(() => {
    const fetchClaimData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINTS.PYTHON_SERVICE}/claim-data`);
        const data = await response.json();
        setIsBlacklisted(data.blacklisted_hospital === "True");
      } catch (error) {
        console.error('Error fetching claim data:', error);
      }
    };

    fetchClaimData();
  }, []);

  useEffect(() => {
    const fetchValidationResults = async () => {
      const url = `${API_ENDPOINTS.PYTHON_SERVICE}/get_processed_data`;
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (response.ok) {
          const data = await response.json();
          setValidationResults(data.validation_results);
        } else {
          console.error('Failed to fetch validation results', await response.text());
        }
      } catch (error) {
        console.error('Error fetching validation results:', error);
      }
    };

    fetchValidationResults();
  }, []);

  useEffect(() => {
    if (!patientId) return;

    const fetchFiles = async () => {
      const url = `${API_ENDPOINTS.EXPRESS_SERVICE}/files/${patientId}`;
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (response.ok) {
          const data = await response.json();
          setFiles(data);
        } else {
          console.error('Failed to fetch files', await response.text());
        }
      } catch (error) {
        console.error('Error fetching files:', error);
      }
    };

    fetchFiles();
  }, [patientId]);

  useEffect(() => {
    const fetchPolicyStatus = async () => {
      const url = `${API_ENDPOINTS.PYTHON_SERVICE}/policy-status`;
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
          },
        });

        if (response.ok) {
          const data = await response.json();
          setPolicyStatus(data);
          setIceAuthorizedAmount(data.ice_authorized_amount);
        } else {
          console.error('Failed to fetch policy status', await response.text());
        }
      } catch (error) {
        console.error('Error fetching policy status:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPolicyStatus();
  }, []);

  const handleItemClick = (doc) => {
    if (!isBlacklisted) {
      setSelectedDocId(doc);
      navigate(`/document/${doc}`);
    }
  };

  if (loading || !validationResults) {
    return <div>Loading...</div>;
  }

  // Calculate total discrepancies
  let totalDiscrepancies = 0;
  const missedDeductionsCount = policyStatus?.Missed_Deductions?.length + policyStatus?.Mispriced_Deductions?.length || 0;
  totalDiscrepancies += missedDeductionsCount;

  // Map over the documents to create their status representations
  const documentStatus = [
    'discrepancy_summary',
    ...Object.keys(validationResults),
    'nonpayables',
  ].map((doc) => {
    let discrepancies = 0;
    let tooltipText = "";

    let validity = "deny"; // Default validity
    const isInvalid = validationResults[doc]?.validity === "invalid";

    if (policyStatus) {
      // Logic for specific documents
      if (doc === 'policy_document') {
        validity = policyStatus.policy_document?.status?.valid ? "approve" : "deny";

        if (validity === "deny") {
          discrepancies = 1;
        }
      } else if (doc === 'policy_wording_document') {
        const {
          sub_limit,
          proportional_deduction,
          total_deductible,
          total_copayment
        } = policyStatus.policy_wording_document || {};

        const allZero = sub_limit === 0 && proportional_deduction === 0 && total_deductible === 0 && total_copayment === 0;
        validity = allZero ? "approve" : "deny";

        if (isInvalid) {
          discrepancies = Object.keys(policyStatus.policy_wording_document.information || {}).length;
        }
      } else if (doc === 'stg_document') {
        const alignmentPercentage = policyStatus.stg_document?.alignment_percentage || 0;
        validity = alignmentPercentage > 70 ? "approve" : "deny";

        if (isInvalid) {
          discrepancies = 1;
        }
      } else if (doc === 'tariff_document') {
        const necessaryCount = policyStatus.tariff?.necessary_items?.length || 0;
        const nonCompliantCount = policyStatus.tariff?.non_compliant_items?.length || 0;
        const unnecessaryCount = policyStatus.tariff?.unnecessary_items?.length || 0;

        discrepancies = necessaryCount + nonCompliantCount + unnecessaryCount;

        if (discrepancies > 0) {
          tooltipText = "Some items in the bill do not match the approved tariff rates.";
        }
      } else if (doc === 'nonpayables') {
        discrepancies = policyStatus.nonpayables?.categories?.items_non_payable_but_billed_in_the_bill?.length || 0;

        if (discrepancies > 0) {
          tooltipText = "The bill includes items that are not covered under the policy.";
        }
      } else if (isInvalid) {
        discrepancies = validationResults[doc]?.reason.length || 0;
      }
    }

    const statusImage = validity === "approve" ? tickImage : crossImage;

    const displayText = doc === 'discrepancy_summary'
      ? <>
          {nameMappings[doc] || toTitleCase(doc)} <span style={{ color: 'red' }}>[{totalDiscrepancies}]</span>
        </>
      : nameMappings[doc] || toTitleCase(doc);

    const reasons = validationResults[doc]?.reason.join("\n") || "";
    const linkTo = `/document/${doc}`;

    const itemClass = doc === 'discrepancy_summary' ? 'result-item discrepancy-summary' : 'result-item';

    // Determine if the document should be clickable
    const isClickable = validity !== "approve";

    // Apply a different class if the document is valid
    const itemClasses = `${itemClass} ${isClickable ? '' : 'valid-document'} ${isBlacklisted ? 'frozen' : ''}`;

    return (
      <div
        key={doc}
        className={itemClasses}
        onClick={() => {
          if (isClickable && !isBlacklisted) {
            handleItemClick(doc);
          }
        }}
      >
        {isClickable ? (
          <Link
            to={linkTo}
            className="result-item-link"
            onClick={(e) => {
              e.stopPropagation();
              setSelectedDocId(doc);
            }}
          >
            <p>
              {displayText}{" "}
              {validity === "deny" && discrepancies > 0 && (
                <span
                  className="discrepancies"
                  style={{ color: 'red' }}
                >
                  [{discrepancies}]
                </span>
              )}
            </p>
          </Link>
        ) : (
          <p className="non-clickable-document">
            {displayText}{" "}
            {validity === "deny" && discrepancies > 0 && (
              <span
                className="discrepancies"
                style={{ color: 'red' }}
              >
                [{discrepancies}]
              </span>
            )}
          </p>
        )}
        {doc !== 'discrepancy_summary' && (
          <img src={statusImage} alt={validity} className="status-image" />
        )}
        {discrepancies > 0 && <div className="tooltip">{tooltipText || reasons}</div>}
      </div>
    );
  });

  const documentLinks = files.map(file => {
    const fileName = file.split(/[/\\]/).pop();
    return (
      <li key={file}>
        {isBlacklisted ? (
          <span className="frozen-document">
            <DocumentIcon className="document-icon" />
            {fileName}
          </span>
        ) : (
          <a href={`${API_ENDPOINTS.EXPRESS_SERVICE}/uploads/patients/${patientId}/${file}`} target="_blank" rel="noopener noreferrer">
            <DocumentIcon className="document-icon" />
            {fileName}
          </a>
        )}
      </li>
    );
  });

  return (
    <div className="claim-details-page">
      <Header
        showDisclaimer={false}
        showSearch={false}
        fetchResponseText={false}
        docId={selectedDocId}
        iceAuthorizedAmount={iceAuthorizedAmount}
      />

      {isBlacklisted && (
        <div className="warning-popup" style={{ fontSize: '20px', fontWeight: "500" }}>
          <p>⚠️ Warning: The hospital associated with this claim is blacklisted.</p>
        </div>
      )}

      {/* Discrepancy Summary */}
      <div className="discrepancy-summary-container">
        <div className="discrepancy-summary">
          {documentStatus[0]}
        </div>
      </div>

      <div className="details-section">
        <div className={`audit-results ${isBlacklisted ? 'frozen' : ''}`}>
          <h2 className="ice-deductions-heading">ICE Deductions</h2>
          {documentStatus.slice(1)}
        </div>
        <div className="documents">
          <ul>
            {documentLinks}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ClaimDetailsPage;
