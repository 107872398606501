// import React from 'react';
// import ReactFlow, { Background, Controls, MiniMap } from 'reactflow';
// import { FaCheckCircle, FaSyncAlt } from 'react-icons/fa';
// import 'reactflow/dist/style.css';
// import './JobPipeline.css'; // Ensure this file contains the updated CSS for node styling

// const jobNodes = [
//   { id: '1', data: { label: 'build-job1', type: 'build', status: 'completed' }, position: { x: 0, y: 50 }, type: 'customNode' },
//   { id: '2', data: { label: 'build-job2', type: 'build', status: 'completed' }, position: { x: 0, y: 150 }, type: 'customNode' },
//   { id: '3', data: { label: 'build-job3', type: 'build', status: 'completed' }, position: { x: 0, y: 250 }, type: 'customNode' },
//   { id: '4', data: { label: 'test-job1', type: 'test', status: 'in-progress' }, position: { x: 250, y: 50 }, type: 'customNode' },
//   { id: '5', data: { label: 'test-job2', type: 'test', status: 'in-progress' }, position: { x: 250, y: 150 }, type: 'customNode' },
//   { id: '6', data: { label: 'test-job3', type: 'test', status: 'in-progress' }, position: { x: 250, y: 250 }, type: 'customNode' },
//   { id: '7', data: { label: 'deploy-job1', type: 'deploy', status: 'pending' }, position: { x: 500, y: 100 }, type: 'customNode' },
//   { id: '8', data: { label: 'deploy-job2', type: 'deploy', status: 'pending' }, position: { x: 500, y: 200 }, type: 'customNode' },
// ];

// const jobEdges = [
//   { id: 'e1-4', source: '1', target: '4', type: 'smoothstep', animated: true, style: { stroke: '#ccc' } },
//   { id: 'e2-5', source: '2', target: '5', type: 'smoothstep', animated: true, style: { stroke: '#ccc' } },
//   { id: 'e3-6', source: '3', target: '6', type: 'smoothstep', animated: true, style: { stroke: '#ccc' } },
//   { id: 'e4-7', source: '4', target: '7', type: 'smoothstep', animated: true, style: { stroke: '#ccc' } },
//   { id: 'e5-8', source: '5', target: '8', type: 'smoothstep', animated: true, style: { stroke: '#ccc' } },
//   { id: 'e6-8', source: '6', target: '8', type: 'smoothstep', animated: true, style: { stroke: '#ccc' } },
// ];

// // Custom Node Component to Display Icons Based on Status
// const CustomNode = ({ data }) => {
//   return (
//     <div className="custom-node">
//       <div className="node-label">
//         {data.label} ({data.type})
//       </div>
//       <div className="job-status">
//         {data.status === 'completed' && <FaCheckCircle style={{ color: 'green' }} />}
//         {data.status === 'in-progress' && <FaSyncAlt style={{ color: '#9E9E9E', animation: 'rotate 2s linear infinite' }} />}
//         {data.status === 'pending' && <FaSyncAlt style={{ color: '#ccc' }} />}
//       </div>
//     </div>
//   );
// };

// const JobPipeline = () => {
//   return (
//     <div style={{ width: '100%', height: '500px' }}>
//       <ReactFlow
//         nodes={jobNodes}
//         edges={jobEdges}
//         nodeTypes={{ customNode: CustomNode }} // Register the custom node type
//         nodesDraggable={false} // Disable dragging nodes for fixed layout
//         fitView
//         style={{ background: '#fff' }}
//       >
//         <MiniMap />
//         <Background />
//         <Controls />
//       </ReactFlow>
//     </div>
//   );
// };

// export default JobPipeline;





// import React from 'react';
// import { FaCheckCircle, FaSyncAlt } from 'react-icons/fa';
// import { BasisCurve } from 'react-svg-curve';
// import './JobPipeline.css'; // Updated CSS

// // Job Nodes Definition
// const jobNodes = [
//   { id: '1', label: 'Verifying member eligibility', type: 'build', status: 'completed', position: { x: 50, y: 150 } },
//   { id: '2', label: 'Parsing the data', type: 'build', status: 'completed', position: { x: 50, y: 250 } },
//   { id: '3', label: 'Question answering with query engines', type: 'test', status: 'completed', position: { x: 350, y: 150 } },
//   { id: '4', label: 'Processing the cost sharing', type: 'test', status: 'in-progress', position: { x: 350, y: 250 } },
//   { id: '5', label: 'Processing tariff and nonpayables', type: 'test', status: 'in-progress', position: { x: 350, y: 350 } },
//   { id: '6', label: 'Highlighting documents', type: 'build', status: 'in-progress', position: { x: 350, y: 450 } },
//   { id: '7', label: 'Saving results', status: 'in-progress', position: { x: 650, y: 150 } },
 
// ];

// // Custom Node Component to Display Icons Based on Status
// const CustomNode = ({ label, type, status, position }) => (
//   <div
//     className="custom-node"
//     style={{
//       position: 'absolute',
//       left: position.x,
//       top: position.y,
//       padding: '10px',
//       border: '1px solid #ddd',
//       borderRadius: '30px',
//       backgroundColor: '#fff',
//       textAlign: 'center',
//       width: '150px',
//     }}
//   >
//     <div className="node-label">
//       {label} ({type})
//     </div>
//     <div className="job-status">
//       {status === 'completed' && <FaCheckCircle style={{ color: 'green' }} />}
//       {status === 'in-progress' && <FaSyncAlt style={{ color: '#9E9E9E', animation: 'rotate 2s linear infinite' }} />}
//       {status === 'pending' && <FaSyncAlt style={{ color: '#ccc' }} />}
//     </div>
//   </div>
// );

// // Job Pipeline Component with Basis Curves
// const JobPipeline = () => {
//   return (
//     <div style={{ position: 'relative', width: '100%', height: '500px' }}>
//       <svg width="100%" height="100%">
//         {/* Draw Connections Between Jobs Using BasisCurve */}
        
//         <BasisCurve data={[[200, 175], [360, 210]]} stroke="gray" strokeWidth={2} />
//         <BasisCurve data={[[200, 275],[360, 210]]} stroke="gray" strokeWidth={2} />
//         <BasisCurve data={[[200, 275],[360, 280]]} stroke="gray" strokeWidth={2} />
//         <BasisCurve data={[[200, 275],[309, 280],[360, 390]]} stroke="gray" strokeWidth={2} />
//         <BasisCurve data={[[200, 275],[309, 280],[360, 470]]} stroke="gray" strokeWidth={2} />

//         <BasisCurve data={[[500, 475],[636, 398],[700, 180]]} stroke="gray" strokeWidth={2} />

//         <BasisCurve data={[[500, 375],[636, 398],[700, 180]]} stroke="gray" strokeWidth={2} />
//         <BasisCurve data={[[500, 275],[636, 398],[700, 180]]} stroke="gray" strokeWidth={2} />
//         <BasisCurve data={[[500, 175],[636, 398],[700, 180]]} stroke="gray" strokeWidth={2} />
//       </svg>

//       {/* Render Job Nodes */}
//       {jobNodes.map((node) => (
//         <CustomNode
//           key={node.id}
//           label={node.label}
//           type={node.type}
//           status={node.status}
//           position={node.position}
//         />
//       ))}
//     </div>
//   );
// };

// export default JobPipeline;






// import React from 'react';
// import { FaCheckCircle, FaSyncAlt } from 'react-icons/fa';
// import { BasisCurve } from 'react-svg-curve';
// import './JobPipeline.css'; // Updated CSS

// // Job Nodes Definition
// const jobNodes = [
//   { id: '1', label: 'Verifying member eligibility', type: 'build', status: 'completed', position: { x: 50, y: 150 } },
//   { id: '2', label: 'Parsing the data', type: 'build', status: 'completed', position: { x: 50, y: 250 } },
//   { id: '3', label: 'Question answering with query engines', type: 'test', status: 'completed', position: { x: 350, y: 150 } },
//   { id: '4', label: 'Processing the cost sharing', type: 'test', status: 'in-progress', position: { x: 350, y: 250 } },
//   { id: '5', label: 'Processing tariff and nonpayables', type: 'test', status: 'in-progress', position: { x: 350, y: 350 } },
//   { id: '6', label: 'Highlighting documents', type: 'build', status: 'in-progress', position: { x: 350, y: 450 } },
//   { id: '7', label: 'Saving results', status: 'in-progress', position: { x: 650, y: 150 } },
// ];

// // Custom Node Component to Display Icons Based on Status
// const CustomNode = ({ label, type, status, position }) => (
//   <div
//     className="custom-node"
//     style={{
//       position: 'absolute',
//       left: position.x,
//       top: position.y,
//       padding: '10px',
//       border: '1px solid #ddd',
//       borderRadius: '30px',
//       backgroundColor: '#fff',
//       textAlign: 'center',
//       width: '150px',
//     }}
//   >
//     <div className="node-label">
//       {label} ({type})
//     </div>
//     <div className="job-status">
//       {status === 'completed' && <FaCheckCircle style={{ color: 'green' }} />}
//       {status === 'in-progress' && <FaSyncAlt style={{ color: '#9E9E9E', animation: 'rotate 2s linear infinite' }} />}
//       {status === 'pending' && <FaSyncAlt style={{ color: '#ccc' }} />}
//     </div>
//   </div>
// );

// // Job Pipeline Component with Basis Curves and Heading
// const JobPipeline = () => {
//   return (
//     <div style={{ position: 'relative', width: '100%', height: '500px' }}>
//       {/* Heading for the Job Pipeline */}
//       <h2 style={{ textAlign: 'left', marginBottom: '20px', paddingLeft:'50px' }}>Initiating Claim Review</h2>

//       <svg width="100%" height="100%">
//         {/* Draw Connections Between Jobs Using BasisCurve */}
//         <BasisCurve data={[[200, 100], [360, 120]]} stroke="gray" strokeWidth={2} />
//         <BasisCurve data={[[200, 200],[360, 200]]} stroke="gray" strokeWidth={2} />
//         <BasisCurve data={[[200, 200],[370, 280]]} stroke="gray" strokeWidth={2} />
//         <BasisCurve data={[[200, 200],[360, 300],[360, 390]]} stroke="gray" strokeWidth={2} />
        

//         <BasisCurve data={[[500, 400],[636, 398],[700, 100]]} stroke="gray" strokeWidth={2} />

//         <BasisCurve data={[[500, 300],[636, 398],[700, 100]]} stroke="gray" strokeWidth={2} />
//         <BasisCurve data={[[500, 200],[636, 398],[700, 100]]} stroke="gray" strokeWidth={2} />
//         <BasisCurve data={[[500, 100],[636, 398],[700, 100]]} stroke="gray" strokeWidth={2} />
//       </svg>

//       {/* Render Job Nodes */}
//       {jobNodes.map((node) => (
//         <CustomNode
//           key={node.id}
//           label={node.label}
//           type={node.type}
//           status={node.status}
//           position={node.position}
//         />
//       ))}
//     </div>
//   );
// };

// export default JobPipeline;





import React, { useEffect, useState } from 'react';
import { FaCheckCircle, FaSyncAlt } from 'react-icons/fa';
import { BasisCurve } from 'react-svg-curve';
import { useNavigate } from 'react-router-dom';
import './JobPipeline.css';

// Job Nodes Definition
const initialJobNodes = [
  { id: '1', label: 'Verifying member eligibility', type: 'build', status: 'pending', position: { x: 50, y: 150 } },
  { id: '2', label: 'Parsing the data', type: 'build', status: 'pending', position: { x: 50, y: 250 } },
  { id: '3', label: 'Question answering with query engines', type: 'test', status: 'pending', position: { x: 350, y: 150 } },
  { id: '4', label: 'Processing the cost sharing', type: 'test', status: 'pending', position: { x: 350, y: 250 } },
  { id: '5', label: 'Processing tariff and nonpayables', type: 'test', status: 'pending', position: { x: 350, y: 350 } },
  { id: '6', label: 'Highlighting documents', type: 'build', status: 'pending', position: { x: 350, y: 450 } },
  { id: '7', label: 'Saving results', status: 'pending', position: { x: 650, y: 150 } },
];


const CustomNode = ({ label, type, status, position }) => (
  <div
    className="custom-node"
    style={{
      position: 'absolute',
      left: position.x,
      top: position.y,
      padding: '10px',
      border: '1px solid #ddd',
      borderRadius: '30px',
      backgroundColor: '#fff',
      textAlign: 'center',
      width: '150px',
    }}
  >
    <div className="node-label">
      {label} ({type})
    </div>
    <div className="job-status">
      {status === 'completed' && <FaCheckCircle style={{ color: 'green' }} />}
      {status === 'in-progress' && <FaSyncAlt className="fa-sync-alt" style={{ color: '#9E9E9E' }} />}
      {status === 'pending' && <FaSyncAlt style={{ color: '#ccc' }} />}
    </div>
  </div>
);

const JobPipeline = () => {
  const [jobNodes, setJobNodes] = useState(initialJobNodes);
  const navigate = useNavigate(); // For programmatic navigation
  const [allTasksCompleted, setAllTasksCompleted] = useState(false); // State to track if all tasks are completed

  // Function to start the jobs automatically when the component mounts
  const startJobs = async () => {
    try {
      const response = await fetch('http://localhost:5000/run-jobs', { method: 'POST' });
      const data = await response.json();
      console.log("Jobs started:", data);
    } catch (error) {
      console.error("Error starting jobs:", error);
    }
  };

  // Enforce sequential display of completed jobs
  const updateJobNodesSequentially = (data) => {
    const updatedNodes = jobNodes.map((node, index) => {
      // If the previous task is not completed, keep current task as 'pending'
      if (index > 0 && jobNodes[index - 1].status !== 'completed') {
        return { ...node, status: 'pending' };
      }

      // Otherwise, update the task based on the real status
      const taskStatus = data[`task${node.id}`];
      return { ...node, status: taskStatus };
    });

    setJobNodes(updatedNodes);

    // Check if all tasks are completed
    const allCompleted = updatedNodes.every((node) => node.status === 'completed');
    setAllTasksCompleted(allCompleted);
  };

  // useEffect hook to trigger job execution and poll for status updates
  useEffect(() => {
    // Start jobs when the component mounts (on page load)
    startJobs();

    // Poll the API every 2 seconds to check job statuses
    const interval = setInterval(async () => {
      try {
        const response = await fetch('http://localhost:5000/status');
        const data = await response.json();
        console.log("Job statuses:", data);

        updateJobNodesSequentially(data); // Ensure jobs display in sequence
      } catch (error) {
        console.error("Error fetching job statuses:", error);
      }
    }, 2000);

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, [jobNodes]);  // The dependency array includes jobNodes to update when they change

  // Redirect to /claim-details if all tasks are completed
  useEffect(() => {
    if (allTasksCompleted) {
      console.log("All tasks are completed! Redirecting to /claim-details...");
      navigate('/claim-details'); // Redirect to claim-details page
    }
  }, [allTasksCompleted, navigate]); // Trigger navigation when allTasksCompleted changes

  return (
    <div style={{ position: 'relative', width: '100%', height: '600px' }}>
      <h2 style={{ textAlign: 'left', marginBottom: '20px', paddingLeft: "50px" }}>Initiating Claim Review</h2>

      <svg width="100%" height="100%">
        {/* Draw Connections Between Jobs Using BasisCurve */}
        <BasisCurve data={[[200, 100], [360, 120]]} stroke="gray" strokeWidth={2} />
        <BasisCurve data={[[200, 200], [360, 200]]} stroke="gray" strokeWidth={2} />
        <BasisCurve data={[[200, 200], [370, 280]]} stroke="gray" strokeWidth={2} />
        <BasisCurve data={[[200, 200], [360, 300], [360, 390]]} stroke="gray" strokeWidth={2} />

        <BasisCurve data={[[500, 400], [636, 398], [700, 100]]} stroke="gray" strokeWidth={2} />
        <BasisCurve data={[[500, 300], [636, 398], [700, 100]]} stroke="gray" strokeWidth={2} />
        <BasisCurve data={[[500, 200], [636, 398], [700, 100]]} stroke="gray" strokeWidth={2} />
        <BasisCurve data={[[500, 100], [636, 398], [700, 100]]} stroke="gray" strokeWidth={2} />
      </svg>

      {/* Render Job Nodes */}
      {jobNodes.map((node) => (
        <CustomNode
          key={node.id}
          label={node.label}
          type={node.type}
          status={node.status}
          position={node.position}
        />
      ))}
    </div>
  );
};

export default JobPipeline;
