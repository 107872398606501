// src/config.js
const PYTHON_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://app.icehealth.in:5000';
const EXPRESS_BASE_URL = process.env.REACT_APP_EXPRESS_BASE_URL || 'https://app.icehealth.in:8000';
// const PYTHON_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://65.2.86.1:5000';
// const EXPRESS_BASE_URL = process.env.REACT_APP_EXPRESS_BASE_URL || 'http://65.2.86.1:8000';
// const PYTHON_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';
// const EXPRESS_BASE_URL = process.env.REACT_APP_EXPRESS_BASE_URL || 'http://localhost:8000';

export const API_ENDPOINTS = {
  PYTHON_SERVICE: PYTHON_BASE_URL,
  EXPRESS_SERVICE: EXPRESS_BASE_URL,
}
 

export default API_ENDPOINTS;